<template>
  <div>
    <h3>{{$t('Fulfillment Services')}}</h3>
    <b-row class="match-height">
      <!-- <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/In Out-logo.png" height="80" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getOtherServiceById(otherServicesIds.INOUT).is_active == true"
              />
              {{$t('In Out')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.inOutModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>-->
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Storage Station-logo.png" height="80" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getOtherServiceById(otherServicesIds.STORAGESTATION).is_active == true"
              />
              {{$t('Storage Station')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.storageStationModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row> 
    <!-- <h3>{{$t('ERP')}}</h3>
    <b-row class="match-height">
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Data Ocean ERP-logo.png" height="35" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getOtherServiceById(otherServicesIds.DATAOCEAN).is_active == true"
              />
              {{$t('Data Ocean ERP')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.dataOceanModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row> -->
    <h3>{{$t('SMS Services')}}</h3>
    <b-row class="match-height">
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Unifonic-logo.png" height="80" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getOtherServiceById(otherServicesIds.UNIFONIC).is_active == true"
              />
              {{$t('Unifonic')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.unifonicModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Unifonic-logo.png" height="80" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getOtherServiceById(otherServicesIds.MSEGAT).is_active == true"
              />
              {{$t('Msegat')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.msegatModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <h3>{{$t('Marketing Tools')}}</h3>
    <b-row class="match-height">
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Snap Pixel-logo.png" height="80" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getOtherServiceById(otherServicesIds.SNAPPIXEL).is_active == true"
              />
              {{$t('Snap Pixel')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.snapPixelModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/TikTok Pixel-logo.png" height="50" /><br><br>
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getOtherServiceById(otherServicesIds.TIKTOKPIXEL).is_active == true"
              />
              {{$t('TikTok Pixel')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.tikTokPixelModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Twitter-logo.png" height="80" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getOtherServiceById(otherServicesIds.TWITTERTRACKING).is_active == true"
              />
              {{$t('Twitter Tracking')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.twitterTrackingModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Facebook-logo.png" height="80" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getOtherServiceById(otherServicesIds.FACEBOOKPIXEL).is_active == true"
              />
              {{$t('Facebook Pixel')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.facebookPixelModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Google Ads-logo.png" height="80" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getOtherServiceById(otherServicesIds.GOOGLEADS).is_active == true"
              />
              {{$t('Google Ads')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.googleAdsModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Google Tag Manager-logo.png" height="50" /><br><br>
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getOtherServiceById(otherServicesIds.GOOGLETAGMANAGER).is_active == true"
              />
              {{$t('Google Tag Manager')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.googleTagManagerModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <h3>{{$t('Reports and Statistics')}}</h3>
    <b-row class="match-height">
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Google Analytics-logo.png" height="60" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getOtherServiceById(otherServicesIds.GOOGLEANALYTICS).is_active == true"
              />
              {{$t('Google Analytics')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.googleAnalyticsModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Hotjar-logo.png" height="80" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getOtherServiceById(otherServicesIds.HOTJAR).is_active == true"
              />
              {{$t('Hotjar')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.hotjarModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Tawk-logo.png" height="80" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getOtherServiceById(otherServicesIds.TAWK).is_active == true"
              />
              {{$t('Tawk')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.tawkModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <h3>{{$t('Accounting and Finance')}}</h3>
    <b-row class="match-height">
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Qoyod-logo.png" height="80" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getOtherServiceById(otherServicesIds.QOYOD).is_active == true"
              />
              {{$t('Qoyod')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.qoyodModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <!-- Unifonic -->
    <unifonic-modal ref="unifonicModal" :config="getOtherServiceById(otherServicesIds.UNIFONIC)"></unifonic-modal>
    <!-- Msegat -->
    <msegat-modal ref="msegatModal" :config="getOtherServiceById(otherServicesIds.MSEGAT)"></msegat-modal>
    <!-- Storage Station -->
    <storage-station-modal ref="storageStationModal" :config="getOtherServiceById(otherServicesIds.STORAGESTATION)"></storage-station-modal>
    <!-- In Out -->
    <in-out-modal ref="inOutModal" :config="getOtherServiceById(otherServicesIds.INOUT)"></in-out-modal>
    <!-- Snap Pixel -->
    <snap-pixel-modal ref="snapPixelModal" :config="getOtherServiceById(otherServicesIds.SNAPPIXEL)"></snap-pixel-modal>
    <!-- TikTok Pixel -->
    <tikTok-pixel-modal ref="tikTokPixelModal" :config="getOtherServiceById(otherServicesIds.TIKTOKPIXEL)"></tikTok-pixel-modal>
    <!-- Facebook Pixel -->
    <facebook-pixel-modal ref="facebookPixelModal" :config="getOtherServiceById(otherServicesIds.FACEBOOKPIXEL)"></facebook-pixel-modal>
    <!-- Twitter Tracking -->
    <twitter-tracking-modal ref="twitterTrackingModal" :config="getOtherServiceById(otherServicesIds.TWITTERTRACKING)"></twitter-tracking-modal>
    <!-- Google Ads -->
    <google-ads-modal ref="googleAdsModal" :config="getOtherServiceById(otherServicesIds.GOOGLEADS)"></google-ads-modal>
    <!-- Google Tag Manager -->
    <google-tag-manager-modal ref="googleTagManagerModal" :config="getOtherServiceById(otherServicesIds.GOOGLETAGMANAGER)"></google-tag-manager-modal>
    <!-- Google Analytics -->
    <google-analytics-modal ref="googleAnalyticsModal" :config="getOtherServiceById(otherServicesIds.GOOGLEANALYTICS)"></google-analytics-modal>
    <!-- Hotjar -->
    <hotjar-modal ref="hotjarModal" :config="getOtherServiceById(otherServicesIds.HOTJAR)"></hotjar-modal>
    <!-- Tawk -->
    <tawk-modal ref="tawkModal" :config="getOtherServiceById(otherServicesIds.TAWK)"></tawk-modal>
    <!-- Qoyod -->
    <qoyod-modal ref="qoyodModal" :config="getOtherServiceById(otherServicesIds.QOYOD)"></qoyod-modal>
    <!-- Data Ocean ERP -->
    <data-ocean-modal ref="dataOceanModal" :config="getOtherServiceById(otherServicesIds.DATAOCEAN)"></data-ocean-modal>
    
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'
import UnifonicModal from './other-services/UnifonicModal.vue'
import MsegatModal from './other-services/MsegatModal.vue'
import InOutModal from './other-services/InOutModal.vue'
import StorageStationModal from './other-services/StorageStationModal.vue'
import SnapPixelModal from './other-services/SnapPixelModal.vue'
import TikTokPixelModal from './other-services/TikTokPixelModal.vue'
import FacebookPixelModal from './other-services/FacebookPixelModal.vue'
import TwitterTrackingModal from './other-services/TwitterTrackingModal.vue'
import GoogleAdsModal from './other-services/GoogleAdsModal.vue'
import GoogleAnalyticsModal from './other-services/GoogleAnalyticsModal.vue'
import GoogleTagManagerModal from './other-services/GoogleTagManagerModal.vue'
import HotjarModal from './other-services/HotjarModal.vue'
import TawkModal from './other-services/TawkModal.vue'
import QoyodModal from './other-services/QoyodModal.vue'
import DataOceanModal from './other-services/DataOceanModal.vue'

export default {
  name: 'OtherServices',
  components: {
    UnifonicModal,
    MsegatModal,
    InOutModal,
    StorageStationModal,
    SnapPixelModal,
    TikTokPixelModal,
    FacebookPixelModal,
    TwitterTrackingModal,
    GoogleAdsModal,
    GoogleTagManagerModal,
    GoogleAnalyticsModal,
    HotjarModal,
    TawkModal,
    QoyodModal,
    DataOceanModal,
  },
  data() {
    return {
      other_services_config: {},
      isLoading: false,
      isLoadingSave: false,
      otherServicesIds: {
        INOUT: 1,
        QOYOD: 2,
        UNIFONIC: 3,
        GOOGLEANALYTICS: 4,
        HOTJAR: 5,
        TAWK: 7,
        SNAPPIXEL: 8,
        TWITTERTRACKING: 9,
        FACEBOOKPIXEL: 10,
        GOOGLEADS: 11,
        LINKARABY: 12,
        TIKTOKPIXEL: 13,
        DATAOCEAN: 14,
        GOOGLETAGMANAGER: 15,
        MSEGAT: 16,
        STORAGESTATION: 17,
      }
    }
  },
  mounted() {
    this.getOtherServices();
  },
  methods: {
    getOtherServices(){
      this.isLoading = true
      useJwt.post('/store/config/get-by-id',{key: 'other_services_config'})
      .then((response) => {
        // console.log(response.data)
        this.other_services_config = response.data.data.other_services_config.other_services_active
        this.isLoading = false
      })
      .catch(response => {
        // console.log(response);
      });
    },
    getOtherServiceById(id) {
      if (Object.keys(this.other_services_config).length) {
        let otherService = this.other_services_config.find(method => { return method.id == id })
        if (otherService) {
          return otherService;
        }
      }
      return {};
    },
    saveOtherService(config, modalName) {
      this.isLoadingSave = true;
      config.store_id = this.$store.state.store.id;
      useJwt.post('/store/config/other-services/save',config)
      .then((response) => {
        // console.log(response.data);
        this.isLoadingSave = false
        this.getOtherServices()
        this.$root.showToast('success', 'save', '')
        this.$bvModal.hide(modalName)
      })
      .catch(error => {
        // console.log(response);
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message)
      });
    }
  }
}
</script>
