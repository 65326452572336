<template>
 <div>
    <b-modal
      id="QoyodModal"
      modal-class="modal-primary"
      :title="$t('Tawk')"
      hide-footer
      centered
    >
      <div>
        <p class="text-center"><b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Qoyod-logo.png" height="100" /></p>
        <p>{{$t('Qoyod offers you an easy accounting system to organize the accounting work for your business')}}</p>
        <p><a href="https://www.qoyod.com">www.qoyod.com</a></p>
        <p>
        <b-button
          variant="primary"
          class="mb-1"
          to="/other-services/qoyod-settings"
        >
          <feather-icon
            icon="SettingsIcon"
            size="15"
          />
          {{$t('Integration Settings')}}
        </b-button>
        </p>
        <!-- <p>
        <b-button
          variant="primary"
          class="mb-1"
          @click="sendInvoicesToQoyod()"
        >
          <feather-icon
            icon="SettingsIcon"
            size="15"
          />
          {{$t('Send Invoices To Qoyod')}}
          <template v-if="loadingSendInvoices">
            <b-spinner small />
          </template>
        </b-button>
        </p>
        <p>
        <b-button
          variant="primary"
          class="mb-1"
          @click="payInvoices()"
        >
          <feather-icon
            icon="SettingsIcon"
            size="15"
          />
          {{$t('Pay Invoices e-pay')}}
          <template v-if="loadingPayInvoices">
            <b-spinner small />
          </template>
        </b-button>
        </p> 
        <input v-model="date" type="text" value="" />-->
      </div>
    </b-modal>
 </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'

export default {
  directives: {
    Ripple,
  },
  props: ['config'],
  data() {
    return {
      loadingSendInvoices: false,
      loadingPayInvoices: false,
      date: ''
    }
  },
  methods: {
    openModal() {
      if (Object.keys(this.config).length > 0) {
        this.config.is_active = this.config.is_active == "1" || this.config.is_active == true ? true : false
      } else {
        this.config.id = this.$parent.otherServicesIds.QOYOD
      }
      this.$bvModal.show("QoyodModal")
    },
    sendInvoicesToQoyod() {
      this.loadingSendInvoices = true
      useJwt.get('/store/config/other-services/qoyod/send-invoices')
      .then((response) => {
        console.log(response.data);
        this.loadingSendInvoices = false
      })
      .catch(error => {
        console.log(response);
        this.loadingSendInvoices = false
        this.$root.showAlert('error', error.response.data.message)
      });
    },
    payInvoices() {
      this.loadingPayInvoices = true
      useJwt.get('/store/config/other-services/qoyod/pay-invoices/'+this.date)
      .then((response) => {
        console.log(response.data);
        this.loadingPayInvoices = false
      })
      .catch(error => {
        console.log(response);
        this.loadingPayInvoices = false
        this.$root.showAlert('error', error.response.data.message)
      });
    }
  }
}
</script>