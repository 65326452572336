<template>
 <div>
    <b-modal
      id="TikTokPixelModal"
      modal-class="modal-primary"
      :title="$t('TikTok Pixel')"
      hide-footer
      centered
    >
      <div>
        <p class="text-center"><b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/TikTok Pixel-logo.png" height="100" /></p>
        <hr>
      </div>
      <b-form @submit.prevent="$parent.saveOtherService(config, 'TikTokPixelModal')">
        <b-form-group
          :label="$t('Is Active ?')"
          label-for="v-is-active"
        >
          <b-form-checkbox
            id="v-is-active"
            class="custom-control-primary"
            v-model="config.is_active"
            name="is_active"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          :label="$t('Pixel ID')"
          label-for="v-pixel-id"
        >
          <b-form-input
            id="v-pixel-id"
            type="text"
            v-model="config.tikTok_pixel_id"
          />
        </b-form-group>
        
        <!-- submit -->
        <b-col cols="12" class="p-0 text-right">

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="ml-1"
          >
            <template v-if="!$parent.isLoadingSave">
              {{$t('Save')}}
            </template>
            <template v-if="$parent.isLoadingSave">
              {{$t('Saveing')}}...
              <b-spinner small />
            </template>
          </b-button>

        </b-col>
      </b-form>
    </b-modal>
 </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  props: ['config'],
  methods: {
    openModal() {
      if (Object.keys(this.config).length > 0) {
        this.config.is_active = this.config.is_active == "1" || this.config.is_active == true ? true : false
      } else {
        this.config.id = this.$parent.otherServicesIds.TIKTOKPIXEL
      }
      this.$bvModal.show("TikTokPixelModal")
    }
  }
}
</script>