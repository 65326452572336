<template>
 <div>
    <b-modal
      id="InOutModal"
      modal-class="modal-primary"
      :title="$t('In Out')"
      hide-footer
      centered
    >
      <div>
        <p class="text-center"><b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/In Out-logo.png" height="100" /></p>
        <!-- <p>{{$t('Tappy Payment provides you with a payment service in Installments and Pay Later to motivate your customers to buy')}}</p> -->
        <!-- <p><a href="https://tabby.ai/ar" target="_blank">tabby.ai</a></p> -->
        <hr>
      </div>
      <b-form @submit.prevent="$parent.saveOtherService(config, 'InOutModal')">
        <b-form-group
          :label="$t('Is Active ?')"
          label-for="v-is-active"
        >
          <b-form-checkbox
            id="v-is-active"
            class="custom-control-primary"
            v-model="config.is_active"
            name="is_active"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          :label="$t('Secret Key')"
          label-for="v-secret-key"
        >
          <b-form-input
            id="v-secret-key"
            type="text"
            v-model="config.secret_key"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Customer Id')"
          label-for="v-customer-id"
        >
          <b-form-input
            id="v-customer-id"
            type="text"
            v-model="config.customer_id"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Special name for store')"
          label-for="v-store-name"
          :description="$t('Used to identify store orders, eg')+`: mhd-inout-8478`"
        >
          <b-form-input
            id="v-store-name"
            type="text"
            v-model="config.store_name"
            placeholder="inout"
          />
        </b-form-group>
        
        <!-- submit -->
        <b-col cols="12" class="p-0 text-right">

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="ml-1"
          >
            <template v-if="!$parent.isLoadingSave">
              {{$t('Save')}}
            </template>
            <template v-if="$parent.isLoadingSave">
              {{$t('Saveing')}}...
              <b-spinner small />
            </template>
          </b-button>

        </b-col>
      </b-form>
    </b-modal>
 </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  props: ['config'],
  methods: {
    openModal() {
      if (Object.keys(this.config).length > 0) {
        this.config.is_active = this.config.is_active == "1" || this.config.is_active == true ? true : false
      } else {
        this.config.id = this.$parent.otherServicesIds.INOUT
      }
      this.$bvModal.show("InOutModal")
    }
  }
}
</script>